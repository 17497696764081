import instance from '../lib/axios'

const PATH = '/notices'

export const postNotices = (data: {name: string; nickname: string; password: string}) =>
  instance({method: 'post', url: PATH, data})

export const getNotices = (params: {start: number; perPage: number; search?: string}) =>
  instance({method: 'get', url: PATH, params})

export const getNotice = (id: number | string) => instance({method: 'get', url: PATH + '/' + id})

export const patchNotice = (
  id: number | string,
  data: {
    name?: string
    nickname?: string
    password?: string
  }
) => instance({method: 'patch', url: PATH + '/' + id, data})

export const deleteNotices = (id: number | string) => instance({method: 'delete', url: PATH + '/' + id})
