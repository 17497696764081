import React from 'react'
import moment from 'moment'
import {DatePicker as AntdDatePicker} from 'antd'
import {IDataPickerProps} from './@types'
import styled from 'styled-components'

function Index({value, onChange, ...rest}: IDataPickerProps) {
  return (
    <DatePicker
      style={{width: '100%'}}
      value={moment(value)}
      showTime={{defaultValue: moment('00:00:00', 'HH:mm:ss')}}
      onChange={onChange}
      disabled
      {...rest}
    />
  )
}

export default Index

const DatePicker = styled(AntdDatePicker)`
  &.ant-picker.ant-picker-disabled {
    background: none;
    input {
      color: inherit;
    }
  }
`
