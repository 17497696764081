import {useCallback, useState} from 'react'

function useEdit() {
  const [disabled, setDisabled] = useState(true)
  const [changeValues, setChangeValues] = useState<any>({})

  const handleChange = useCallback((values: any) => {
    setDisabled(false)
    setChangeValues((prev: any) => ({...prev, ...values}))
  }, [])

  const handleReset = useCallback(() => {
    setDisabled(true)
  }, [])

  return {disabled, changeValues, handleChange, handleReset}
}

export default useEdit
