import React, {useCallback, useEffect, useState} from 'react'
import {DatePicker, Form, Input, InputNumber, message, Select} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useParams, useNavigate} from 'react-router'
import EditForm from '../../components/Form/EditForm'
import Upload from '../../components/Upload/Upload'
import {uploadImage} from '../../lib/uploadImage'
import {deleteNotices, getNotice, patchNotice} from '../../api/notices'
import ImageEditor from '../../components/Editor/ImageEditor'
import moment from 'moment'

export default function NoticesDetail() {
  const {id} = useParams()
  const [form] = useForm()
  const [data, setData] = useState<any>(undefined)
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (changeValues: any) => {
      if (!id) return
      try {
        if (changeValues.titleImage) {
          changeValues.titleImage =
            typeof changeValues.titleImage[0] !== 'string'
              ? await uploadImage(changeValues.titleImage[0])
              : changeValues.titleImage
        }

        if (changeValues.hashtags) {
          changeValues.hashtags = changeValues.hashtags.map((hashtag: string) =>
            hashtag[0] !== '#' ? '#' + hashtag : hashtag
          )
        }

        await patchNotice(id, changeValues)
        message.success('성공적으로 수정되었습니다.')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [id]
  )

  const handleDelete = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    if (id && confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deleteNotices(id)
        navigate('/notices')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    } else if (!id) return
  }, [id, navigate])

  const fetchData = useCallback(async (id: any) => {
    const res = await getNotice(id)
    res.data.hashtags = res.data.hashtags.map(({name}: {name: string}) => name)
    res.data.createdAt = moment(res.data.createdAt)
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(id)
  }, [fetchData, id])

  return (
    <EditForm title="공지사항" form={form} initialValues={data} onFinish={handleSubmit} onDelete={handleDelete}>
      <Form.Item name="id" label="Id">
        <InputNumber disabled />
      </Form.Item>

      <Form.Item name="title" label="제목" rules={[{min: 4, max: 100, message: '4 ~ 100자 이내로 입력해주세요'}]}>
        <Input placeholder="제목을 입력해주세요" />
      </Form.Item>

      <Form.Item name="titleImage" label="썸네일">
        <Upload title="" max={1} values={data?.titleImage ? [data?.titleImage] : []} />
      </Form.Item>

      <Form.Item name="content" label="내용">
        <ImageEditor
          data={data?.content}
          onChange={(e: React.ChangeEvent, editor: object | any) => form.setFieldsValue({content: editor.getData()})}
        />
      </Form.Item>

      <Form.Item name="hashtags" label="해시태그">
        <Select mode="tags" placeholder="해시태그를 선택해주세요" />
      </Form.Item>

      <Form.Item name="createdAt" label="업로드일시">
        <DatePicker format={'YYYY-MM-DD hh:mm:ss'} style={{width: '100%'}} />
      </Form.Item>
    </EditForm>
  )
}
