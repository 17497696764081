import {Button, Checkbox, Col, Form, Input, message, Row, Typography} from 'antd'
import {useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {postAuth} from '../../api/auth'
import AuthLayout from '../../layouts/AuthLayout'
import {UserContext} from '../../providers/UserProvider'

export default function LoginPage() {
  const navigate = useNavigate()
  const user = useContext(UserContext)

  const onFinish = async (values: any) => {
    try {
      const res = await postAuth({
        data: values,
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      if (res.status === 200)
        user.dispatch({
          type: 'LOG_IN',
          payload: {isLogin: true, ...res.data}
        })
      navigate('/administrators')
    } catch (e: any) {
      if (e.response.status >= 400 && e.response.status < 500) message.error(e.response.data.message)
    }
  }

  return (
    <AuthLayout>
      <Row
        gutter={[16, 16]}
        align="middle"
        justify="center"
        style={{
          margin: 'auto',
          marginTop: '0',
          marginBottom: '0',
          width: '50vw',
          height: 'fit-content'
        }}
      >
        <Col>
          <Typography.Title level={3}>관리자 로그인</Typography.Title>
        </Col>
        <Col span={24}>
          <Form
            name="basic"
            initialValues={{remember: true}}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            style={{width: '70%', maxWidth: '400px', margin: 'auto'}}
          >
            <Form.Item
              label="사용자이름"
              name="name"
              rules={[{required: true, message: 'Please input your username!'}]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="패스워드"
              name="password"
              rules={[{required: true, message: 'Please input your password!'}]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>로그인 정보 기억하기</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" style={{width: '100%'}}>
                로그인
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AuthLayout>
  )
}
