import React, {useCallback, useEffect, useState} from 'react'
import {Form, Input, Switch} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useNavigate} from 'react-router'
import CreateForm from '../../components/Form/CreateForm'
import Upload from '../../components/Upload/Upload'
import {uploadImage} from '../../lib/uploadImage'
import {postMembers} from '../../api/members'
import {ChromePicker} from 'react-color'
import moment from 'moment'

export default function MembersCreate() {
  const [form] = useForm()
  const navigate = useNavigate()
  const [color, setColor] = useState('#000000')
  const [hidden, setHidden] = useState(false)

  const handleColorChange: any = useCallback((val: any) => {
    setColor(val.hex)
  }, [])

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        values.nameHexColor = color
        if (values.profileImage) {
          values.profileImage = await uploadImage(values.profileImage[0])
        }
        values.createdAt = moment()
        await postMembers({...values, hidden})
        navigate(window.location.pathname.replace('/create', ''))
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [color, navigate, hidden]
  )

  useEffect(() => form.setFieldsValue({nameHexColor: '#000000'}))

  const handleChecked = () => {
    setHidden((prevState) => !prevState)
  }

  return (
    <CreateForm title="직원" form={form} onFinish={handleSubmit}>
      <Form.Item label="공개">
        <Switch checkedChildren="비공개" unCheckedChildren="공개" onChange={handleChecked} checked={hidden} />
      </Form.Item>

      <Form.Item
        name="name"
        label="이름"
        rules={[
          {required: true, message: '이름을 입력해주세요'},
          {min: 1, max: 50, message: '1 ~ 50자 이내로 입력해주세요'}
        ]}
      >
        <Input placeholder="이름을 입력해주세요" style={{color, fontWeight: 'bold'}} />
      </Form.Item>

      <Form.Item
        name="nameHexColor"
        label="이름 색상 Hex 값"
        rules={[{required: true, message: '이름 색상 Hex 값을 입력해주세요'}]}
      >
        <ChromePicker color={color} onChange={handleColorChange} />
      </Form.Item>

      <Form.Item
        name="position"
        label="포지션"
        rules={[
          {required: true, message: '포지션을 입력해주세요'},
          {min: 1, max: 50, message: '1 ~ 50자 이내로 입력해주세요'}
        ]}
      >
        <Input placeholder="포지션을 입력해주세요" />
      </Form.Item>

      <Form.Item name="profileImage" label="프로필 이미지">
        <Upload title="" max={1} />
      </Form.Item>
    </CreateForm>
  )
}
