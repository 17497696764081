import React, {useCallback} from 'react'
import {Col, Form, Input, Row} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {postAdministrators} from '../../api/administrators'
import {useNavigate} from 'react-router'
import CreateForm from '../../components/Form/CreateForm'

export default function AdministratorsCreate() {
  const [form] = useForm()
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        await postAdministrators(values)
        navigate(window.location.pathname.replace('/create', ''))
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('운영자 생성에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [navigate]
  )

  return (
    <CreateForm title="운영진" form={form} onFinish={handleSubmit}>
      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item
            name="name"
            label="이름"
            rules={[
              {required: true, message: '이름를 입력해주세요'},
              {
                min: 4,
                max: 20,
                message: '4 ~ 20자 이내로 입력해주세요'
              }
            ]}
          >
            <Input placeholder="이름을 입력해주세요" />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            name="nickname"
            label="닉네임"
            rules={[
              {required: true, message: '닉네임을 입력해주세요'},
              {min: 2, max: 40, message: '2 ~ 40자 이내로 입력해주세요'}
            ]}
          >
            <Input placeholder="닉네임을 입력해주세요" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="password"
        label="비밀번호"
        rules={[
          {required: true, message: '비밀번호를 입력해주세요'},
          {pattern: /^[0-9a-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/, message: '비밀번호 형식이 올바르지 않습니다'}
        ]}
      >
        <Input.Password autoComplete="new-password" placeholder="비밀번호를 입력해주세요" />
      </Form.Item>
    </CreateForm>
  )
}
