import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Select} from 'antd'
import queryString from 'query-string'
import dayjs from 'dayjs'
import {getListParams} from '../../lib/getStartPerPage'
import {useLocation, useNavigate} from 'react-router'
import ListLayout from '../../layouts/ListLayout'
import {getContacts} from '../../api/contacts'
import {Option} from 'antd/lib/mentions'
import Table from '../../components/Table'

const Columns: any = [
  {title: '번호', dataIndex: 'id', key: 'id', sorter: (a: {id: number}, b: {id: number}) => a.id - b.id},
  {title: '이름', dataIndex: 'name', key: 'name'},
  {
    title: '상태',
    dataIndex: 'status',
    key: 'status',
    render: (status: string) => (status === 'received' ? '응답 대기 중' : '응답 완료')
  },
  {
    title: '업로드일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {createdAt: string}, b: {createdAt: string}) =>
      dayjs(a.createdAt).millisecond() - dayjs(b.createdAt).millisecond()
  },
  {
    title: '최종수정일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {updatedAt: string}, b: {updatedAt: string}) =>
      dayjs(a.updatedAt).millisecond() - dayjs(b.updatedAt).millisecond()
  }
]

export default function ContactsPage() {
  const [data, setData] = useState({data: [], total: -1})
  const location: any = useLocation()
  const navigate: any = useNavigate()
  const parsed: any = useMemo(
    () => ({
      page: 1,
      pageSize: 10,
      ...queryString.parse(location.search, {parseBooleans: true}),
      ...queryString.parse(location.status, {parseBooleans: true})
    }),
    [location.search, location.status]
  )

  const onStatusSearch = useCallback(
    (value: string) => {
      if (value) navigate({search: queryString.stringify({...parsed, status: value})})
      else {
        delete parsed.status
        navigate({status: queryString.stringify(parsed)})
      }
    },
    [parsed, navigate]
  )

  const actions = useMemo(() => {
    return [
      <Select placeholder="상태별 필터" onChange={onStatusSearch}>
        <Option value="">--</Option>
        <Option value="received">received</Option>
        <Option value="checked">checked</Option>
      </Select>
    ]
  }, [onStatusSearch])

  const fetchData = useCallback(async (parsed: any) => {
    const res = await getContacts(getListParams(parsed))
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(parsed)
    window.history.replaceState({}, '', location.pathname)
  }, [fetchData, location, parsed])

  return (
    <>
      <ListLayout actions={actions}>
        <Table
          parsed={parsed}
          data={data}
          columns={Columns}
          onRow={(record: any) => ({
            onClick: () => navigate(location.pathname + '/' + record.id)
          })}
        />
      </ListLayout>
    </>
  )
}
