import React, {useCallback, useEffect, useState} from 'react'
import {Form, Input, InputNumber, message, Select} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useParams, useNavigate} from 'react-router'
import EditForm from '../../components/Form/EditForm'
import {deleteContacts, getContact, patchContact} from '../../api/contacts'
import {Option} from 'antd/lib/mentions'
import DatePicker from '../../components/DatePicker'

export default function ContactsDetail() {
  const {id} = useParams()
  const [form] = useForm()
  const [data, setData] = useState<any>(undefined)
  const navigate = useNavigate()
  const {TextArea} = Input

  const handleSubmit = useCallback(
    async (changeValues: any) => {
      if (!id) return
      try {
        await patchContact(id, changeValues)
        message.success('성공적으로 수정되었습니다.')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [id]
  )

  const handleDelete = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    if (id && confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deleteContacts(id)
        navigate('/contacts')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    } else if (!id) return
  }, [id, navigate])

  const fetchData = useCallback(async (id: any) => {
    const res = await getContact(id)
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(id)
  }, [fetchData, id])

  return (
    <EditForm title="문의" form={form} initialValues={data} onFinish={handleSubmit} onDelete={handleDelete}>
      <Form.Item name="id" label="Id">
        <InputNumber disabled />
      </Form.Item>

      <Form.Item name="name" label="이름" rules={[{min: 1, max: 30, message: '1 ~ 30자 이내로 입력해주세요'}]}>
        <Input placeholder="이름을 입력해주세요" />
      </Form.Item>

      <Form.Item name="status" label="상태">
        <Select placeholder="상태를 선택하세요">
          <Option value="received">응답 대기 중</Option>
          <Option value="checked">응답 완료</Option>
        </Select>
      </Form.Item>

      <Form.Item name="email" label="이메일" rules={[{type: 'email', message: '이메일 형식이 아닙니다'}]}>
        <Input placeholder="이메일을 입력해주세요" />
      </Form.Item>

      <Form.Item name="content" label="내용">
        <TextArea placeholder="내용을 입력해주세요" />
      </Form.Item>

      <Form.Item name="memo" label="메모">
        <Input placeholder="메모를 입력해주세요" />
      </Form.Item>

      <Form.Item name="createdAt" label="업로드일자">
        <DatePicker />
      </Form.Item>
    </EditForm>
  )
}
