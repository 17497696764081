import {getFiles, postFile} from '../api/files'
import axios from 'axios'

export const uploadImage = async (file: File, kind?: string, onProgress?: (event: {percent: number}) => void) => {
  if (typeof file === 'string') return file
  let data

  for (let i = 0; i < 10; i++) {
    const res = await getFiles({type: 'image', mimeType: file.type})
    if (res.data.url !== 'https://s3.ap-northeast-2.amazonaws.com/') {
      data = res.data
      break
    }
  }

  await axios.put(data.url, file, {
    headers: {'Content-Type': file.type},
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(progressEvent.loaded * 100)
      if (onProgress) onProgress({percent: percentCompleted})
    }
  })

  const {
    data: {url}
  } = await postFile({type: 'image', kind: kind || 'products', path: data.path})

  return url
}
