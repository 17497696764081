import instance from '../lib/axios'

const PATH = '/blogs'

export const postBlogs = (data: {name: string; nickname: string; password: string}) =>
  instance({method: 'post', url: PATH, data})

export const getBlogs = (params: {start: number; perPage: number; search?: string}) =>
  instance({method: 'get', url: PATH, params})

export const getBlog = (id: number | string) => instance({method: 'get', url: PATH + '/' + id})

export const patchBlog = (
  id: number | string,
  data: {
    name?: string
    nickname?: string
    password?: string
  }
) => instance({method: 'patch', url: PATH + '/' + id, data})

export const deleteBlogs = (id: number | string) => instance({method: 'delete', url: PATH + '/' + id})
