import {UserOutlined} from '@ant-design/icons'
import {Button, Dropdown, Layout, Menu, message} from 'antd'
import {useCallback, useContext} from 'react'
import {useNavigate} from 'react-router-dom'
import {deleteAuth} from '../../api/auth'
import {UserContext} from '../../providers/UserProvider'

export default function Header() {
  const {Header} = Layout
  const user = useContext(UserContext)
  const navigate = useNavigate()

  const handleLogout = useCallback(async () => {
    try {
      await deleteAuth()
      user.dispatch({type: 'LOG_OUT', payload: {isLogin: false}})
      navigate('/login')
    } catch (e: any) {
      if (e.response.status === 500) message.error('로그아웃에 실패했습니다. 잠시 후 다시 시도해주세요.')
    }
  }, [navigate, user])

  return (
    <Header>
      <div style={{marginLeft: '98.7%'}}>
        <Dropdown
          overlay={
            <Menu
              style={{textAlign: 'center'}}
              onClick={handleLogout}
              items={[{label: '로그아웃', key: 'user-logout'}]}
            />
          }
          placement="bottom"
        >
          <Button shape="circle">
            <UserOutlined />
          </Button>
        </Dropdown>
      </div>
    </Header>
  )
}
