import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Select} from 'antd'
import queryString from 'query-string'
import dayjs from 'dayjs'
import {getListParams} from '../../lib/getStartPerPage'
import {useLocation, useNavigate} from 'react-router'
import ListLayout from '../../layouts/ListLayout'
import {getWorks} from '../../api/works'
import {Option} from 'antd/lib/mentions'
import Table from '../../components/Table'

const Columns: any = [
  {title: '번호', dataIndex: 'id', key: 'id', sorter: (a: {id: number}, b: {id: number}) => a.id - b.id},
  {
    title: '썸네일',
    dataIndex: 'titleImage',
    key: 'titleImage',
    render: (titleImage: string) => <img src={titleImage} alt={titleImage} width="150px" />
  },
  {title: '타입', dataIndex: 'type', key: 'type'},
  {title: '제목', dataIndex: 'title', key: 'title'},
  {
    title: '업로드일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {createdAt: string}, b: {createdAt: string}) =>
      dayjs(a.createdAt).millisecond() - dayjs(b.createdAt).millisecond()
  },
  {
    title: '최종수정일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {updatedAt: string}, b: {updatedAt: string}) =>
      dayjs(a.updatedAt).millisecond() - dayjs(b.updatedAt).millisecond()
  }
]

export default function WorksPage() {
  const [data, setData] = useState({data: [], total: -1})
  const location = useLocation()
  const navigate: any = useNavigate()
  const parsed: any = useMemo(
    () => ({
      page: 1,
      pageSize: 10,
      groupId: undefined,
      ...queryString.parse(location.search, {parseBooleans: true})
    }),
    [location.search]
  )

  const layoutProps = useMemo(() => {
    return {
      search: {placeholder: '검색', allowClear: true}
    }
  }, [])

  const onTypeSearch = useCallback(
    (value: string) => {
      if (value) navigate({search: queryString.stringify({...parsed, type: value})})
      else {
        delete parsed.type
        navigate({type: queryString.stringify(parsed)})
      }
    },
    [parsed, navigate]
  )

  const actions = useMemo(() => {
    return [
      <Select placeholder="타입 필터" onChange={onTypeSearch}>
        <Option value="">--</Option>
        <Option value="weplanet">weplanet</Option>
        <Option value="client">client</Option>
      </Select>,
      <Button key="addUser" onClick={() => navigate(`${location.pathname}/create`)}>
        포트폴리오 추가
      </Button>
    ]
  }, [location.pathname, navigate, onTypeSearch])

  const fetchData = useCallback(async (parsed: any) => {
    const res = await getWorks(getListParams(parsed))
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(parsed)
    window.history.replaceState({}, '', location.pathname)
  }, [fetchData, location, parsed])

  return (
    <>
      <ListLayout {...layoutProps} actions={actions}>
        <Table
          parsed={parsed}
          data={data}
          columns={Columns}
          onRow={(record: any) => ({
            onClick: () => navigate(location.pathname + '/' + record.id)
          })}
        />
      </ListLayout>
    </>
  )
}
