import React, {PropsWithChildren, useState} from 'react'
import {useNavigate} from 'react-router'
import {Button, Card, Col, Form, FormProps, Row, Space, Typography} from 'antd'
import {useForm} from 'antd/es/form/Form'

import useEdit from '../../hooks/useEdit'

function Index({
  title,
  initialValues,
  onFinish,
  onDelete,
  children,
  ...rest
}: PropsWithChildren<IFormProps & FormProps>) {
  const [form] = useForm()
  const navigate = useNavigate()
  const edit = useEdit()
  const [loading, setLoading] = useState(false)

  return (
    <Card>
      <Typography.Title level={3}># {title}</Typography.Title>
      <br />
      <Form
        form={form}
        layout="vertical"
        initialValues={initialValues}
        onReset={edit.handleReset}
        onValuesChange={(changeValues, values) => {
          if (rest.onValuesChange) rest.onValuesChange(changeValues, values)
          edit.handleChange(changeValues)
        }}
        onFinish={async (values) => {
          if (onFinish) {
            if (!onDelete) setLoading(true)
            await onFinish(values, edit.changeValues)
            setLoading(false)
          }
        }}
        {...rest}
      >
        {children}
        <Form.Item>
          <Row justify="space-between">
            <Col>
              <Space>
                <Button htmlType="button" onClick={() => navigate(-1)}>
                  이전으로
                </Button>
              </Space>
            </Col>
            <Col>
              <Space>
                {onFinish && (
                  <>
                    <Button htmlType="reset" disabled={edit.disabled}>
                      초기화
                    </Button>

                    {!loading ? (
                      <Button htmlType="submit" type="primary">
                        {/*<Button htmlType="submit" type="primary" disabled={edit.disabled}>*/}
                        저장
                      </Button>
                    ) : (
                      <Button disabled>로딩 중..</Button>
                    )}
                  </>
                )}
                {onDelete && (
                  <Button htmlType="button" danger onClick={onDelete}>
                    삭제
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  )
}

export default Index
