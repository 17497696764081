import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from 'antd'
import queryString from 'query-string'
import dayjs from 'dayjs'
import {getListParams} from '../../lib/getStartPerPage'
import {useLocation, useNavigate} from 'react-router'
import ListLayout from '../../layouts/ListLayout'
import {getNotices} from '../../api/notices'
import Table from '../../components/Table'

const Columns: any = [
  {title: '번호', dataIndex: 'id', key: 'id', sorter: (a: {id: number}, b: {id: number}) => a.id - b.id},
  {
    title: '썸네일',
    dataIndex: 'titleImage',
    key: 'titleImage',
    render: (titleImage: string) => <img src={titleImage} alt={titleImage} width="150px" />
  },
  {title: '제목', dataIndex: 'title', key: 'title'},
  {
    title: '업로드일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {createdAt: string}, b: {createdAt: string}) =>
      dayjs(a.createdAt).millisecond() - dayjs(b.createdAt).millisecond()
  },
  {
    title: '최종수정일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {updatedAt: string}, b: {updatedAt: string}) =>
      dayjs(a.updatedAt).millisecond() - dayjs(b.updatedAt).millisecond()
  }
]

export default function NoticesPage() {
  const [data, setData] = useState({data: [], total: -1})
  const location = useLocation()
  const navigate = useNavigate()
  const parsed = useMemo(
    () => ({
      page: 1,
      pageSize: 10,
      groupId: undefined,
      ...queryString.parse(location.search, {parseBooleans: true})
    }),
    [location.search]
  )

  const layoutProps = useMemo(() => {
    return {
      search: {placeholder: '검색', allowClear: true}
    }
  }, [])

  const actions = useMemo(() => {
    return [
      <Button key="addUser" onClick={() => navigate(`${location.pathname}/create`)}>
        공지사항 추가
      </Button>
    ]
  }, [location.pathname, navigate])

  const fetchData = useCallback(async (parsed: any) => {
    const res = await getNotices(getListParams(parsed))
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(parsed)
    window.history.replaceState({}, '', location.pathname)
  }, [fetchData, location, parsed])

  return (
    <>
      <ListLayout {...layoutProps} actions={actions}>
        <Table
          parsed={parsed}
          columns={Columns}
          data={data}
          onRow={(record: any) => ({
            onClick: () => navigate(location.pathname + '/' + record.id)
          })}
        />
      </ListLayout>
    </>
  )
}
