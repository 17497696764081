import React, {useCallback, useEffect, useState} from 'react'
import {Col, Form, Input, InputNumber, message, Row} from 'antd'
import {useForm} from 'antd/es/form/Form'
import DatePicker from '../../components/DatePicker'
import {useParams, useNavigate} from 'react-router'
import EditForm from '../../components/Form/EditForm'
import {deleteAdministrators, getAdministrator, patchAdministrator} from '../../api/administrators'

export default function AdministratorsDetail() {
  const {id} = useParams()
  const [form] = useForm()
  const [data, setData] = useState(undefined)
  const navigate = useNavigate()

  const handleSubmit = useCallback(
    async (changeValues: any) => {
      if (!id) return
      try {
        await patchAdministrator(id, changeValues)
        message.success('성공적으로 수정되었습니다.')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [id]
  )

  const handleDelete = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    if (id && confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deleteAdministrators(id)
        navigate('/administrators')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    } else if (!id) return
  }, [id, navigate])

  const fetchData = useCallback(async (id: any) => {
    const res = await getAdministrator(id)
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(id)
  }, [fetchData, id])

  return (
    <EditForm title="운영진" form={form} initialValues={data} onFinish={handleSubmit} onDelete={handleDelete}>
      <Form.Item name="id" label="Id">
        <InputNumber disabled />
      </Form.Item>

      <Row gutter={8}>
        <Col flex="auto">
          <Form.Item name="name" label="이름" rules={[{min: 4, max: 20, message: '4 ~ 20자 이내로 입력해주세요'}]}>
            <Input placeholder="이름을 입력해주세요" />
          </Form.Item>
        </Col>
        <Col flex="auto">
          <Form.Item
            name="nickname"
            label="닉네임"
            rules={[{min: 2, max: 40, message: '2 ~ 40자 이내로 입력해주세요'}]}
          >
            <Input placeholder="닉네임을 입력해주세요" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name="password"
        label="비밀번호 변경"
        rules={[
          {
            pattern: /^[0-9a-zA-Z!@#$%^&*()?+-_~=/]{6,40}$/,
            message: '비밀번호 형식이 올바르지 않습니다'
          }
        ]}
      >
        <Input.Password autoComplete="new-password" placeholder="비밀번호를 입력해주세요" />
      </Form.Item>

      <Form.Item name="createdAt" label="업로드일시">
        <DatePicker />
      </Form.Item>
    </EditForm>
  )
}
