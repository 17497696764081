import {Skeleton, Table as AntdTable} from 'antd'
import {useNavigate} from 'react-router-dom'
import queryString from 'query-string'

export default function Table({parsed, data, columns, onRow}: {parsed: any; data: any; columns: any; onRow?: any}) {
  const navigate = useNavigate()

  if (data.total === -1) {
    return (
      <>
        <Skeleton active />
        <Skeleton active />
      </>
    )
  }
  return (
    <AntdTable
      rowKey="id"
      columns={columns}
      dataSource={data?.data}
      pagination={{total: data?.total}}
      onChange={(pagination: any) => {
        navigate({
          search: queryString.stringify({
            ...parsed,
            page: pagination.current,
            pageSize: pagination.pageSize
          })
        })
      }}
      onRow={onRow}
    />
  )
}
