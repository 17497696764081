export const getListParams = ({page, pageSize, ...search}: {page: number; pageSize: number; [key: string]: any}) => {
  Object.entries(search).forEach(([key, value]) => {
    if (!value) delete search[key]
  })

  return {
    start: (page - 1) * pageSize,
    perPage: pageSize,
    ...search
  }
}
