import instance from '../lib/axios'
import {AxiosRequestConfig} from 'axios'

const PATH = '/auth'

export const postAuth = (config?: AxiosRequestConfig) => instance({url: PATH, method: 'post', ...config})

export const getAuth = (config?: AxiosRequestConfig) => instance({url: PATH, method: 'get', ...config})

export const deleteAuth = (config?: AxiosRequestConfig) => instance({url: PATH, method: 'delete', ...config})
