import React, {useMemo} from 'react'
import Editor from './index'
import {uploadImage} from '../../lib/uploadImage'

const uploadAdapter = (loader: any) => {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        loader.file.then(async (file: any) => {
          const imagePath = await uploadImage(file, 'image')
          resolve({default: imagePath})
        })
      })
    }
  }
}

function uploadPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader)
  }
}

const defaultConfig = {
  extraPlugins: [uploadPlugin],
  placeholder: '내용을 입력하세요'
}

function ImageEditor({config: propsConfig, ...rest}: IEditorProps) {
  const config = useMemo(() => {
    if (!propsConfig) return defaultConfig
    return {...defaultConfig, ...propsConfig}
  }, [propsConfig])

  return <Editor {...rest} config={config} />
}

export default ImageEditor
