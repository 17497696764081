import styled from 'styled-components'

const Logoes = styled.div`
  width: 64px;
  height: 64px;
  margin: 16px auto;
  background: url('/logo.svg') rgb(255, 255, 255) no-repeat center;
  background-size: 80%;
  border-radius: 50%;
`

export default Logoes
