import {Layout, Menu, MenuProps} from 'antd'
import {Link, useLocation} from 'react-router-dom'
import React from 'react'
import Logoes from '../Logoes'
import {SideBarRoutes} from '../../routes'

export default function SideBar() {
  const {pathname} = useLocation()
  const {Sider} = Layout

  const items: MenuProps['items'] = SideBarRoutes.map(({name, icon, path, children}: any) => {
    return {
      key: path,
      label: (
        <Link to={path}>
          {icon}
          <span style={{marginLeft: '10px'}}>{name}</span>
        </Link>
      )
    }
  })

  return (
    <Sider style={{background: 'white'}}>
      <Link to="/">
        <Logoes />
      </Link>
      <Menu mode="inline" selectedKeys={[`/${pathname.split('/')?.[1]}`]} items={items} />
    </Sider>
  )
}
