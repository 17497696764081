import instance from '../lib/axios'
import {AxiosRequestConfig} from 'axios'

export interface IGetFiles {
  type: 'image' | 'file'
  mimeType: string
  name?: string
}

export function postFile(data: {type: string; kind: string; path: string}) {
  return instance({url: '/files/upload', method: 'post', data})
}

export async function getFiles(params: IGetFiles, config?: AxiosRequestConfig) {
  return instance({url: '/files/upload', method: 'get', params, ...config})
}
