import React, {useCallback, useEffect, useState} from 'react'
import {DatePicker, Form, Input, Select} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useNavigate} from 'react-router'
import CreateForm from '../../components/Form/CreateForm'
import Upload from '../../components/Upload/Upload'
import TextArea from 'antd/lib/input/TextArea'
import {uploadImage} from '../../lib/uploadImage'
import {postRecruits} from '../../api/recruits'
import moment from 'moment'
import {ChromePicker} from 'react-color'
import ImageEditor from '../../components/Editor/ImageEditor'

export default function RecruitsCreate() {
  const [form] = useForm()
  const navigate = useNavigate()
  const [color, setColor] = useState('#000000')

  const handleColorChange: any = useCallback((val: any) => {
    setColor(val.hex)
  }, [])

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        values.positionHexColor = color

        if (values.titleImage) {
          values.titleImage = await uploadImage(values.titleImage[0])
        }

        if (values.endDate) {
          values.endDate = moment(values.endDate).format('YYYY-MM-DD')
        }

        if (values.hashtags) {
          values.hashtags = values.hashtags.map((hashtag: string) => (hashtag[0] !== '#' ? '#' + hashtag : hashtag))
        }

        values.createdAt = moment()

        await postRecruits(values)
        navigate(window.location.pathname.replace('/create', ''))
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [navigate, color]
  )

  useEffect(() => form.setFieldsValue({nameHexColor: '#000000'}))

  return (
    <CreateForm title="채용" form={form} onFinish={handleSubmit}>
      <Form.Item
        name="title"
        label="제목"
        rules={[
          {required: true, message: '제목을 입력해주세요'},
          {min: 4, max: 100, message: '4 ~ 100자 이내로 입력해주세요'}
        ]}
      >
        <Input placeholder="제목을 입력해주세요" />
      </Form.Item>

      <Form.Item name="titleImage" label="썸네일">
        <Upload title="" max={1} />
      </Form.Item>

      <Form.Item name="position" label="포지션" rules={[{required: true, message: '포지션을 입력해주세요'}]}>
        <TextArea placeholder="포지션을 입력해주세요" style={{color}} />
      </Form.Item>

      <Form.Item
        name="positionHexColor"
        label="포지션 색상 Hex 값"
        rules={[{required: true, message: '포지션 색상 Hex 값을 입력해주세요'}]}
      >
        <ChromePicker color={color} onChange={handleColorChange} />
      </Form.Item>

      <Form.Item name="content" label="내용" rules={[{required: true, message: '내용을 입력해주세요'}]}>
        <ImageEditor
          onChange={(e: React.ChangeEvent, editor: object | any) => form.setFieldsValue({content: editor.getData()})}
        />
      </Form.Item>

      <Form.Item name="endDate" label="마감 기한">
        <DatePicker />
      </Form.Item>

      <Form.Item name="hashtags" label="해시태그">
        <Select mode="tags" placeholder="해시태그를 선택해주세요" />
      </Form.Item>
    </CreateForm>
  )
}
