import React, {PropsWithChildren} from 'react'
import Form from './index'
import {Skeleton, Form as AntdForm, Card, Typography, FormProps} from 'antd'
import {useParams} from 'react-router'
import DatePicker from '../DatePicker'

const {Item} = AntdForm

function EditForm({title, initialValues, children, ...rest}: PropsWithChildren<IFormProps & FormProps>) {
  const {id} = useParams()

  return (
    <>
      {initialValues ? (
        <Form title={`${title} ${id}`} initialValues={initialValues} {...rest}>
          {children}
          <Item label="최종수정일시">
            <DatePicker />
          </Item>
        </Form>
      ) : (
        <Card>
          <Typography.Title level={3}># {`${title} ${id}`}</Typography.Title>
          <br />
          <Skeleton />
        </Card>
      )}
    </>
  )
}

export default EditForm
