import React, {createContext, Dispatch, PropsWithChildren, useCallback, useEffect, useReducer, useState} from 'react'
import {isAxiosError} from '../lib/axios'
import {Spin} from 'antd'
import {ResultStatusType} from 'antd/lib/result'

import {getAuth} from '../api/auth'
import {ErrorPage500} from '../pages'
import {UserContextProps} from '../types/UserContextProps'

interface Action {
  type: 'UPDATE_USER' | 'DELETE_USER' | 'LOG_OUT' | 'LOG_IN'
  payload?: UserContextProps
}

const defaultValue: UserContextProps = {
  isLogin: false
}

const reducer = (state = defaultValue, action: Action) => {
  switch (action.type) {
    case 'UPDATE_USER':
    case 'LOG_IN':
      return {...state, ...action.payload}
    case 'DELETE_USER':
    case 'LOG_OUT':
      return defaultValue
    default:
      return state
  }
}

export const UserContext = createContext<{state: UserContextProps; dispatch: Dispatch<Action>}>({
  state: defaultValue,
  dispatch: () => null
})

const UserProvider = ({children}: PropsWithChildren<any>) => {
  const [loading, setLoading] = useState(true)
  const [errorStatus, setErrorStatus] = useState<ResultStatusType | undefined>(undefined)
  const [state, dispatch] = useReducer(reducer, defaultValue)

  const updateUser = useCallback(async () => {
    try {
      if (['/login'].includes(window.location.pathname)) return

      const res = await getAuth()
      if (res.status === 200) dispatch({type: 'UPDATE_USER', payload: {isLogin: true, ...res.data}})
    } catch (error) {
      if (isAxiosError(error)) {
        switch (error.response?.status) {
          case 500:
            setErrorStatus(500)
            break
          default:
            dispatch({type: 'DELETE_USER', payload: {isLogin: false}})
            break
        }
      }
    } finally {
      setLoading(false)
    }
  }, [dispatch])

  useEffect(() => {
    updateUser()
  }, [updateUser])

  if (errorStatus === 500) return <ErrorPage500 />
  return <>{loading ? <Spin /> : <UserContext.Provider value={{state, dispatch}}>{children}</UserContext.Provider>}</>
}

export default UserProvider
