import React, {useCallback, useEffect, useState} from 'react'
import {DatePicker, Form, Input, InputNumber, message, Switch} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useParams, useNavigate} from 'react-router'
import EditForm from '../../components/Form/EditForm'
import Upload from '../../components/Upload/Upload'
import {uploadImage} from '../../lib/uploadImage'
import {deleteMembers, getMember, patchMember, PostMember} from '../../api/members'
import {ChromePicker} from 'react-color'
import moment from 'moment'

export default function MembersDetail() {
  const {id} = useParams()
  const [form] = useForm()
  const [data, setData] = useState<any>(undefined)
  const navigate = useNavigate()
  const [color, setColor] = useState('#000000')

  const handleColorChange: any = useCallback((val: any) => {
    setColor(val.hex)
  }, [])

  const handleSubmit = useCallback(
    async (changeValues: Partial<PostMember>) => {
      if (!id) return
      try {
        if (changeValues.profileImage) {
          changeValues.profileImage =
            typeof changeValues.profileImage[0] !== 'string'
              ? await uploadImage(changeValues.profileImage[0])
              : changeValues.profileImage
        }
        changeValues.nameHexColor = color
        await patchMember(id, changeValues)
        message.success('성공적으로 수정되었습니다.')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [id, color]
  )

  const handleDelete = useCallback(async () => {
    // eslint-disable-next-line no-restricted-globals
    if (id && confirm('정말로 삭제하시겠습니까?')) {
      try {
        await deleteMembers(id)
        navigate('/members')
      } catch (e: any) {
        if (e.response) alert(`Error: ${e.response.data.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    } else if (!id) return
  }, [id, navigate])

  const handleReset = useCallback(() => data && setColor(data?.nameHexColor), [data])

  const fetchData = useCallback(async (id: any) => {
    const res = await getMember(id)
    res.data.createdAt = moment(res.data.createdAt)
    setColor(res.data.nameHexColor)
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(id)
  }, [fetchData, id])

  return (
    <EditForm
      title="직원"
      form={form}
      initialValues={data}
      onFinish={handleSubmit}
      onDelete={handleDelete}
      onReset={handleReset}
    >
      <Form.Item name="id" label="Id">
        <InputNumber disabled />
      </Form.Item>

      <Form.Item name="hidden" label="공개">
        <Switch checkedChildren="비공개" unCheckedChildren="공개" defaultChecked={data?.hidden} />
      </Form.Item>

      <Form.Item name="name" label="이름" rules={[{min: 1, max: 50, message: '1 ~ 50자 이내로 입력해주세요'}]}>
        <Input placeholder="이름을 입력해주세요" style={{color, fontWeight: 'bold'}} />
      </Form.Item>

      <Form.Item
        name="nameHexColor"
        label="이름 색상 Hex 값"
        rules={[{required: true, message: '이름 색상 Hex 값을 입력해주세요'}]}
      >
        <ChromePicker color={color} onChange={handleColorChange} />
      </Form.Item>

      <Form.Item name="position" label="포지션" rules={[{min: 1, max: 50, message: '1 ~ 50자 이내로 입력해주세요'}]}>
        <Input placeholder="포지션을 입력해주세요" />
      </Form.Item>

      <Form.Item name="profileImage" label="프로필 이미지">
        <Upload title="" max={1} values={data?.profileImage ? [data?.profileImage] : []} />
      </Form.Item>

      <Form.Item name="createdAt" label="업로드일시">
        <DatePicker format={'YYYY-MM-DD hh:mm:ss'} style={{width: '100%'}} />
      </Form.Item>
    </EditForm>
  )
}
