import React, {useCallback, useState} from 'react'
import {Form, Input, Select, Switch} from 'antd'
import {useForm} from 'antd/es/form/Form'
import {useNavigate} from 'react-router'
import CreateForm from '../../components/Form/CreateForm'
import Upload from '../../components/Upload/Upload'
import {uploadImage} from '../../lib/uploadImage'
import {postWorks} from '../../api/works'
import {Option} from 'antd/lib/mentions'
import ImageEditor from '../../components/Editor/ImageEditor'
import moment from 'moment'

export default function WorksCreate() {
  const [form] = useForm()
  const navigate = useNavigate()
  const [hidden, setHidden] = useState(false)

  const handleSubmit = useCallback(
    async (values: any) => {
      try {
        if (values.titleImage) {
          values.titleImage = await uploadImage(values.titleImage[0])
        }

        if (values.hashtags) {
          values.hashtags = values.hashtags.map((hashtag: string) => (hashtag[0] !== '#' ? '#' + hashtag : hashtag))
        }

        values.createdAt = moment()

        await postWorks({...values, hidden})
        navigate(window.location.pathname.replace('/create', ''))
      } catch (e: any) {
        if (e?.response?.data) alert(`Error: ${e?.response?.data?.message}`)
        else alert('요청에 실패했습니다. 잠시후 다시 시도해주세요')
      }
    },
    [hidden, navigate]
  )

  const handleChecked = () => {
    setHidden((prevState) => !prevState)
  }

  return (
    <CreateForm title="포트폴리오" form={form} onFinish={handleSubmit}>
      <Form.Item label="공개">
        <Switch checkedChildren="비공개" unCheckedChildren="공개" onChange={handleChecked} checked={hidden} />
      </Form.Item>

      <Form.Item name="type" label="타입" rules={[{required: true, message: '타입을 선택해주세요'}]}>
        <Select placeholder="타입을 선택해주세요">
          <Option value="weplanet">weplanet</Option>
          <Option value="client">client</Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="title"
        label="제목"
        rules={[
          {required: true, message: '제목을 입력해주세요'},
          {min: 4, max: 100, message: '4 ~ 100자 이내로 입력해주세요'}
        ]}
      >
        <Input placeholder="제목을 입력해주세요" />
      </Form.Item>

      <Form.Item name="titleImage" label="썸네일">
        <Upload title="" max={1} />
      </Form.Item>

      <Form.Item name="content" label="내용" rules={[{required: true, message: '내용을 입력해주세요'}]}>
        <ImageEditor
          onChange={(e: React.ChangeEvent, editor: object | any) => form.setFieldsValue({content: editor.getData()})}
        />
      </Form.Item>

      <Form.Item name="hashtags" label="해시태그">
        <Select mode="tags" placeholder="해시태그를 선택해주세요" />
      </Form.Item>
    </CreateForm>
  )
}
