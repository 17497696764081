import { Layout } from "antd";
import React from "react";

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Layout>
      <Layout.Content
        style={{
          minHeight: "100vh",
          height: "100vh",
          display: "flex",
        }}
      >
        {children}
      </Layout.Content>
    </Layout>
  );
}
