import axios, {AxiosError} from 'axios'

const publicUrl = ['/verifications/confirm', '/files/upload']

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 20000
})

instance.interceptors.response.use((response) => response, errorHandler)

if (process.env.NODE_ENV !== 'production') {
  instance.interceptors.request.use(
    (config) => {
      const {url, method, params, data} = config
      // eslint-disable-next-line no-console
      console.log(`[Request]: ${JSON.stringify({url, params, method, data}, null, 2)}`)
      return config
    },
    (error) => Promise.reject(error)
  )
  instance.interceptors.response.use((response) => {
    // eslint-disable-next-line no-console
    console.log(`[Response Data]: ${JSON.stringify(response.data, null, 2)}`)
    return response
  }, errorHandler)
} else {
  instance.interceptors.response.use((response) => response, errorHandler)
}

async function errorHandler(e: AxiosError) {
  if (!e.response) throw e
  // eslint-disable-next-line no-console
  console.log(`[Error Data]: ${JSON.stringify(e.response.data, null, 2)}`)
  if (!publicUrl.includes(`${e.config.url}`) && e.response.status === 401) {
    window.alert('로그인이 필요합니다.')
    window.location.href = '/login'
  } else if (e.response.status === 403) {
    window.open('/error/403', '_self')
  } else if (e.response.status === 500) {
    window.open('/error/500', '_self')
  }
  throw e
}

function isAxiosError(candidate: any): candidate is AxiosError {
  return candidate.isAxiosError
}

export default instance
export {isAxiosError}
