import React, {PropsWithChildren} from 'react'
import {FormProps} from 'antd'
import Form from './index'

function CreateForm({title, children, ...rest}: PropsWithChildren<IFormProps & FormProps>) {
  return (
    <Form title={`${title} 생성`} {...rest}>
      {children}
    </Form>
  )
}

export default CreateForm
