import instance from '../lib/axios'

const PATH = '/works'

export interface PostWork {
  title: string
  type: 'weplanet' | 'client'
  content: string
  createdAt: string
  hidden?: boolean
  year?: number[]
  platform?: 'IOS' | 'AOS' | 'WEB'
  titleImage?: string
  hashtags?: string[]
}

export const postWorks = (data: PostWork) => instance({method: 'post', url: PATH, data})

export const getWorks = (params: {start: number; perPage: number; search?: string}) =>
  instance({method: 'get', url: PATH, params})

export const getWork = (id: number | string) => instance({method: 'get', url: PATH + '/' + id})

export const patchWork = (id: number | string, data: Partial<PostWork>) =>
  instance({method: 'patch', url: PATH + '/' + id, data})

export const deleteWorks = (id: number | string) => instance({method: 'delete', url: PATH + '/' + id})
