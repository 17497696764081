import instance from '../lib/axios'

const PATH = '/members'

export interface PostMember {
  name: string
  position: string
  nameHexColor: string
  hidden: boolean
  createdAt: string
  profileImage?: string
}

export const postMembers = (data: PostMember) => instance({method: 'post', url: PATH, data})

export const getMembers = (params: {start: number; perPage: number; search?: string}) =>
  instance({method: 'get', url: PATH, params})

export const getMember = (id: number | string) => instance({method: 'get', url: PATH + '/' + id})

export const patchMember = (id: number | string, data: Partial<PostMember>) =>
  instance({method: 'patch', url: PATH + '/' + id, data})

export const deleteMembers = (id: number | string) => instance({method: 'delete', url: PATH + '/' + id})
