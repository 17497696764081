import {BrowserRouter, useRoutes} from 'react-router-dom'
import LoginPage from './pages/Login'
import {
  AuditOutlined,
  FormOutlined,
  IdcardOutlined,
  ImportOutlined,
  MessageOutlined,
  NumberOutlined,
  TeamOutlined
} from '@ant-design/icons'
import AdministratorsPage from './pages/Administrarors'
import AdministratorsCreate from './pages/Administrarors/Create'
import IndexPage from './pages/Index/index'
import DashboardLayout from './layouts/DashboardLayout'
import AdministratorsDetail from './pages/Administrarors/Detail'
import BlogsPage from './pages/Blogs'
import BlogsCreate from './pages/Blogs/Create'
import BlogsDetail from './pages/Blogs/Detail'
import ContactsPage from './pages/Contacts'
import ContactsDetail from './pages/Contacts/Detail'
import MembersPage from './pages/Members'
import MembersDetail from './pages/Members/Detail'
import MembersCreate from './pages/Members/Create'
import NoticesPage from './pages/Notices'
import NoticesCreate from './pages/Notices/Create'
import NoticesDetail from './pages/Notices/Detail'
import RecruitsPage from './pages/Recruits'
import RecruitsCreate from './pages/Recruits/Create'
import RecruitsDetail from './pages/Recruits/Detail'
import WorksPage from './pages/Works'
import WorksCreate from './pages/Works/Create'
import WorksDetail from './pages/Works/Detail'
import Error404 from './pages/Errors/Error404'
import Error403 from './pages/Errors/Error403'
import Error500 from './pages/Errors/Error500'

const Login = {
  path: '/login',
  element: <LoginPage />
}

const Index = {
  name: '운영자 관리',
  path: '/',
  element: <IndexPage />
}

const Administrarors = [
  {
    name: '운영자 관리',
    icon: <NumberOutlined />,
    path: '/administrators',
    element: <AdministratorsPage />
  },
  {
    name: '운영자 생성',
    path: '/administrators/create',
    element: <AdministratorsCreate />
  },
  {
    name: '운영자 수정',
    path: '/administrators/:id',
    element: <AdministratorsDetail />
  }
]

const Blogs = [
  {
    name: '블로그 관리',
    icon: <FormOutlined />,
    path: '/blogs',
    element: <BlogsPage />
  },
  {
    name: '게시글 생성',
    path: '/blogs/create',
    element: <BlogsCreate />
  },
  {
    name: '운영자 수정',
    path: '/blogs/:id',
    element: <BlogsDetail />
  }
]

const Contacts = [
  {
    name: '문의 관리',
    icon: <MessageOutlined />,
    path: '/contacts',
    element: <ContactsPage />
  },
  {
    name: '문의 수정',
    path: '/contacts/:id',
    element: <ContactsDetail />
  }
]

const Members = [
  {
    name: '직원 관리',
    icon: <TeamOutlined />,
    path: '/members',
    element: <MembersPage />
  },
  {
    name: '직원 생성',
    path: '/members/create',
    element: <MembersCreate />
  },
  {
    name: '직원 수정',
    path: '/members/:id',
    element: <MembersDetail />
  }
]

const Notices = [
  {
    name: '공지사항 관리',
    icon: <AuditOutlined />,
    path: '/notices',
    element: <NoticesPage />
  },
  {
    name: '공지사항 생성',
    path: '/notices/create',
    element: <NoticesCreate />
  },
  {
    name: '공지사항 수정',
    path: '/notices/:id',
    element: <NoticesDetail />
  }
]

const Recruits = [
  {
    name: '채용 관리',
    icon: <ImportOutlined />,
    path: '/recruits',
    element: <RecruitsPage />
  },
  {
    name: '채용 생성',
    path: '/recruits/create',
    element: <RecruitsCreate />
  },
  {
    name: '채용 수정',
    path: '/recruits/:id',
    element: <RecruitsDetail />
  }
]

const Works = [
  {
    name: '포트폴리오 관리',
    icon: <IdcardOutlined />,
    path: '/works',
    element: <WorksPage />
  },
  {
    name: '채용 생성',
    path: '/works/create',
    element: <WorksCreate />
  },
  {
    name: '채용 수정',
    path: '/works/:id',
    element: <WorksDetail />
  }
]

const Errors = [
  {
    path: '*',
    element: <Error404 />
  },
  {
    path: '/error/403',
    element: <Error403 />
  },
  {
    path: '/error/500',
    element: <Error500 />
  }
]

const Routes = () => {
  return useRoutes([
    Login,
    ...Administrarors,
    ...Blogs,
    ...Contacts,
    ...Members,
    ...Notices,
    ...Recruits,
    ...Works,
    ...Errors,
    Index
  ])
}

const RoutesWithBrowser = () => (
  <BrowserRouter>
    <DashboardLayout>
      <Routes />
    </DashboardLayout>
  </BrowserRouter>
)

export const SideBarRoutes = [Administrarors[0], Blogs[0], Contacts[0], Members[0], Notices[0], Recruits[0], Works[0]]

export default RoutesWithBrowser
