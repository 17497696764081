import {Layout} from 'antd'
import React from 'react'
import {useLocation} from 'react-router-dom'
import Header from '../components/Header'
import SideBar from '../components/SideBar'

export default function DashboardLayout({children}: {children: React.ReactNode}) {
  const location = useLocation()

  if (location.pathname === '/login') {
    return <>{children}</>
  } else
    return (
      <Layout>
        <SideBar />
        <Layout>
          <Header />
          <Layout.Content
            style={{
              minHeight: 'calc(100vh - 64px)',
              height: '100%',
              margin: '20px'
            }}
          >
            {children}
          </Layout.Content>
        </Layout>
      </Layout>
    )
}
