import React, {PropsWithChildren, useCallback} from 'react'
import {Card, Col as AntdCol, Input, Row, Space} from 'antd'
import styled from 'styled-components'
import {SearchProps} from 'antd/es/input'
import queryString from 'query-string'
import {useNavigate, useLocation} from 'react-router-dom'

interface IListLayout {
  title?: string
  search?: SearchProps
  actions?: JSX.Element | JSX.Element[]
  bulkActions?: JSX.Element | JSX.Element[]
  side?: JSX.Element | JSX.Element[]
  selects?: number
}

function ListLayout({title, actions, bulkActions, side, search, selects, children}: PropsWithChildren<IListLayout>) {
  const location = useLocation()
  const parsed: any = queryString.parse(location.search)
  const navigate = useNavigate()

  const onSearch = useCallback(
    (value: string) => {
      if (value) navigate({search: queryString.stringify({...parsed, search: value})})
      else {
        delete parsed.search
        navigate({search: queryString.stringify(parsed)})
      }
    },
    [parsed, navigate]
  )

  return (
    <Row>
      {side && (
        <Col span={3}>
          <Card className="card">{side}</Card>
        </Col>
      )}
      <Col span={side ? 21 : 24}>
        <Card title={title} className="card">
          <Row gutter={8} justify="end">
            {search && (
              <Col flex={1}>
                <Input.Search placeholder="검색" defaultValue={parsed.search || ''} onSearch={onSearch} {...search} />
              </Col>
            )}
            {actions && (
              <Col>
                <Space>{actions}</Space>
              </Col>
            )}
          </Row>
          <BulkActions show={`${!!selects}`} align="middle" justify="space-between">
            {bulkActions && !!selects && (
              <>
                <Col>{selects}개 선택됨</Col>
                <Col>
                  <Space>{bulkActions}</Space>
                </Col>
              </>
            )}
          </BulkActions>
          <Row style={{marginTop: '1rem'}}>
            <Col flex={1}>{children}</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  )
}

export default ListLayout

const Col = styled(AntdCol)`
  .card {
    min-height: 85vh;
    height: 100%;
  }
`

const BulkActions = styled(Row)<{show: 'false' | 'true'}>`
  margin-top: 1rem;
  opacity: ${(props) => (props.show === 'true' ? 1 : 0)};
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 10px;
  border-radius: 4px;
  color: var(--ant-primary-color);
  border: var(--ant-primary-color-outline);
  background: var(--ant-primary-color-outline);
`
