import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button} from 'antd'
import queryString from 'query-string'
import dayjs from 'dayjs'
import {getListParams} from '../../lib/getStartPerPage'
import {useLocation, useNavigate} from 'react-router'
import ListLayout from '../../layouts/ListLayout'
import {getMembers} from '../../api/members'
import Table from '../../components/Table'

const Columns: any = [
  {title: '번호', dataIndex: 'id', key: 'id', sorter: (a: {id: number}, b: {id: number}) => a.id - b.id},
  {
    title: '프로필 이미지',
    dataIndex: 'profileImage',
    key: 'profileImage',
    render: (profileImage: string) => <img src={profileImage} alt={profileImage} width="50px" />
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    render: (name: string, {nameHexColor}: {nameHexColor: string}) => (
      <span style={{fontWeight: 'bold', color: nameHexColor}}>{name}</span>
    )
  },
  {title: '포지션', dataIndex: 'position', key: 'position'},
  {
    title: '업로드일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt: string) => dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {createdAt: string}, b: {createdAt: string}) =>
      dayjs(a.createdAt).millisecond() - dayjs(b.createdAt).millisecond()
  },
  {
    title: '최종수정일시',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (updatedAt: string) => dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss'),
    sorter: (a: {updatedAt: string}, b: {updatedAt: string}) =>
      dayjs(a.updatedAt).millisecond() - dayjs(b.updatedAt).millisecond()
  }
]

export default function MembersPage() {
  const [data, setData] = useState({data: [], total: -1})
  const location = useLocation()
  const navigate = useNavigate()
  const parsed = useMemo(
    () => ({
      page: 1,
      pageSize: 10,
      groupId: undefined,
      ...queryString.parse(location.search, {parseBooleans: true})
    }),
    [location.search]
  )

  const actions = useMemo(() => {
    return [
      <Button key="addUser" onClick={() => navigate(`${location.pathname}/create`)}>
        직원 추가
      </Button>
    ]
  }, [location.pathname, navigate])

  const fetchData = useCallback(async (parsed: any) => {
    const res = await getMembers(getListParams(parsed))
    setData(res.data)
  }, [])

  useEffect(() => {
    fetchData(parsed)
    window.history.replaceState({}, '', location.pathname)
  }, [fetchData, location, parsed])

  return (
    <>
      <ListLayout actions={actions}>
        <Table
          parsed={parsed}
          data={data}
          columns={Columns}
          onRow={(record: any) => ({
            onClick: () => navigate(location.pathname + '/' + record.id)
          })}
        />
      </ListLayout>
    </>
  )
}
